import * as React from "react"
import '../style/style.css';
import Layout from '../components/Layout';
import MainBanner from '../components/products/MainBanner';
import Ingredients from "../components/products/Ingredients";
import Benefits from '../components/products/Benefits';
import Varieties from '../components/products/Varieties';
import { graphql,useStaticQuery } from 'gatsby';
import { getImage, withArtDirection } from "gatsby-plugin-image";


const PeAdMyGPage = () => {
    const data = useStaticQuery(graphql`
      query{
        file(relativePath: {eq: "Productos/Fondos/PerrosAdultosMyG.jpg"}) {
          childImageSharp {
            gatsbyImageData
            id
          }
        }

        mobileHeader: file(relativePath: {eq: "Productos/Mobile/PerrosAdultosMyG.jpg"}) {
          childImageSharp {
            gatsbyImageData
            id
          }
        }

        allFile(
          filter: {relativeDirectory: {eq: "Ingredientes/PC"}}
          sort: {fields: base}
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
              id
            }
          }
        }

        transicion: file(relativePath: {eq: "Transicion/pamyg.png"}) {
          childImageSharp {
            gatsbyImageData
          }
        }

        site {
          siteMetadata {
            productosCarousel {
              id
              button
              color
              description
              historyButton
              igText
              knowText
              presentation
              subtitle
              title
              igUrl
            }
            ingredientes {
              adultosmyg {
                title
                description
                color
                id
              }
            }
            nutritionTable {
              adultosmyg {
                energy
                ingredients
                values {
                  factorEn
                  factorEs
                  max
                  min
                }
              }
            }
            benefitsTitle {
              adultosmyg
            }
            benefits {
              adultosmyg {
                description
                title
              }
            }
            varieties {
              adultosmyg {
                color
                description
                href
                id
                kgs {
                  kg
                }
                subtitle
                title
              }
            }
          }
        }

        benefitsBackground: file(relativePath: {eq: "Beneficios/Fondos/PAMYG.jpg"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2010) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        benefitsBackgroundMobile: file(relativePath: {eq: "Beneficios/FondosMobile/PAMYG.jpg"}) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        dogPhoto: file(relativePath: {eq: "Mascotas/PAMYG.png"}) {
          childImageSharp {
            gatsbyImageData
          }
        }

        itemsIcons: allFile(
          filter: {relativeDirectory: {eq: "Beneficios/Items/PAMYG"}}
          sort: {order: ASC, fields: base}
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        varietiesImgs: allFile(filter: {relativeDirectory: {eq: "Variedades/AdultosMyG"}}
        sort: {fields: base, order: ASC}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }

      }
    `);

    const imgMainBanner = withArtDirection(getImage(data.file.childImageSharp.gatsbyImageData),[
      {
        media: '(max-width: 420px)',
        image: getImage(data.mobileHeader.childImageSharp.gatsbyImageData),
      },
    ]);

    const pageInfo = data.site.siteMetadata.productosCarousel[2];

    const ingredientes = data.site.siteMetadata.ingredientes[0].adultosmyg;

    const images = data.allFile.nodes;

    const transicion = data.transicion.childImageSharp.gatsbyImageData;

    const nutritionTable = data.site.siteMetadata.nutritionTable[0].adultosmyg;

    const benefitsBackground = [
      data.benefitsBackground.childImageSharp.fluid,
    {
        ...data.benefitsBackgroundMobile.childImageSharp.fluid,
        media: `(max-width: 420px)`
    },
  ]

    const dogPhoto = data.dogPhoto.childImageSharp.gatsbyImageData;

    const itemsIcons = data.itemsIcons.nodes;

    const benefits = data.site.siteMetadata.benefits[0].adultosmyg;

    const benefitTitle = data.site.siteMetadata.benefitsTitle.adultosmyg;

    const varieties = data.site.siteMetadata.varieties[0].adultosmyg;

    const varietiesImg = data.varietiesImgs.nodes;

    return (
    <main>
    <title>Livra | Perros Adultos (Medianos y Grandes)</title>
    <Layout>
        <MainBanner className={'perros-adultos-img'} img={imgMainBanner}  info={pageInfo}/>
        <Ingredients ingredientes={ingredientes} images={images} transicion={transicion} nutritionTable={nutritionTable} tipo={'Pamyg'}/>
        <Benefits benefitText={benefitTitle} background={benefitsBackground} dogPhoto={dogPhoto} itemsIcons={itemsIcons} benefits={benefits}/>
        <Varieties data={varieties} images={varietiesImg} />
    </Layout>
  </main>
  );
}

export default PeAdMyGPage;

export { Head } from "../components/Head";